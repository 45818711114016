.title[data-v-0333c0ab] {
  height: 75px;
  line-height: 75px;
  margin-bottom: 0;
}
.table-container[data-v-0333c0ab] {
  height: 100%;
  background: #fff;
  padding: var(--0333c0ab-padding);
  overflow: auto;
}
.table-container[data-v-0333c0ab] .el-loading-mask {
  z-index: 1000;
}
